<script>
	import { notification } from '$src/stores.js';
	import { slide } from 'svelte/transition';
	import { clickOutside } from '$utils/actions.js';
	import CloseIcon from './icon/CloseIcon.svelte';
	import VerifiedIcon from './icon/VerifiedIcon.svelte';
</script>

<!-- TBD: Refactor Notification component for profile and authorize -->
<div
	class="text-sm py-2 p-1 w-full mx-auto flex items-center justify-center flex-shrink-0 {window.isWalletAuthorizeApp
		? 'mt-2 rounded-md'
		: ''}"
	style="z-index: 60;"
	data-test={$notification.type === 'success' ? 'success-notification' : 'error-notification'}
	class:bg-green-500={$notification.type === 'success'}
	class:bg-red-500={$notification.type === 'error'}
	class:text-charcoal={$notification.type === 'success'}
	class:text-white={$notification.type === 'error'}
	transition:slide|local
	use:clickOutside={() => notification.clear()}
>
	<div role="alert" class="relative w-full flex justify-between items-center">
		<div class="w-3/4 md:w-full mx-auto flex justify-center items-center text-center">
			<span class="inline-flex items-center">
				{#if $notification?.text?.startsWith(':verified:')}
					<VerifiedIcon />
					<span class="ml-1.5">{@html $notification.text.replace(':verified:', '') || ''}</span>
				{:else}
					<span>{@html $notification.text || ''}</span>
				{/if}
			</span>
		</div>
		<div class="absolute right-4 inline-flex items-center justify-end">
			<button
				aria-label="Close"
				data-test="close-notification"
				on:click={() => notification.clear()}
				class="group"
			>
				<CloseIcon />
			</button>
		</div>
	</div>
</div>
